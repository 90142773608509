import cn from 'classnames'
import { useEffect, useState, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { XMarkIcon } from '@heroicons/react/24/outline'
import s from './FeatureBar.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const FeatureBar = ({ title, description, className, action, hide, close }) => {
  const containerRef = useRef()
  const [height, setHeight] = useState()
  const { asPath } = useRouter()
  const { setHtmlRelativeLink } = useRelativeLink()

  const showPop = useMemo(() => {
    const blacklist = [
      '/collections/portable-power-stations',
      '/collections/solar-panels',
      '/collections/solar-generators',
    ]
    const hiddenCookiesPopForLCP = blacklist.find((item) =>
      asPath?.includes(item)
    )
    return !hide && !hiddenCookiesPopForLCP
  }, [asPath, hide])

  const rootClassName = cn(
    s.root,
    {
      transform: true,
      'translate-y-0 opacity-100': showPop,
      'translate-y-full opacity-0': !showPop,
    },
    className
  )

  useEffect(() => {
    function updateHeight() {
      setHeight(containerRef.current.clientHeight)
    }
    updateHeight()
    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  return (
    <div ref={containerRef} className={rootClassName}>
      <div className={s.rootWrap}>
        <div className="max-w-[850px] l:w-full l:pr-0 min-l:text-left">
          <span
            className="block font-medium md:inline "
            dangerouslySetInnerHTML={{
              __html: setHtmlRelativeLink({ html: title }),
            }}
          ></span>
          {description && (
            <span
              className="mb-6 block font-medium md:mb-0 md:ml-2"
              dangerouslySetInnerHTML={{
                __html: setHtmlRelativeLink({ html: description }),
              }}
            >
              {setHtmlRelativeLink({ html: description })}
            </span>
          )}
        </div>
        {action && action}
        {close && (
          <button onClick={close} className={s.close} aria-label="close">
            <XMarkIcon className="w-full" />
          </button>
        )}
        {showPop && (
          <style jsx global>
            {`
              .productPurchaseBar {
                bottom: ${height}px;
              }
            `}
          </style>
        )}
      </div>
    </div>
  )
}

export default FeatureBar
